import React, { useContext, useState } from "react";
import ReactDOM from "react-dom";

// utils-imports
import { randomUUID } from "../../../services/randomUUID";

// component-imports
import FormHeading from "../../../UI/FormHeading/FormHeading";
import SnackbarMessage from "../../SnackbarMessage/SnackbarMessage";

// context-imports
import OnBoardCompanyPAContext from "../../../contexts/CustomerManagementPAContext/OnBoardCompanyPAContext";

// api-config-imports
import { APIConfig } from "../../../services/apiConfiguration";
import axios from "axios";
import paAPIEndpoints from "../../../config/pa_api_endpoints/manage_customer_pa/endpoint";

// styles import
import "./OnBoardCredentialsPA.scss";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const OnBoardCredentialsPA = () => {
  // ^ context
  const { companyID, toCompanyDetails } = useContext(OnBoardCompanyPAContext);

  // const dmoReferenceID = randomUUID();

  // & btn submit state
  const [enableDoneBtn, setEnableDoneBtn] = useState(false);
  const [disableSendCredentials, setDisableSendCredentials] = useState(false);

  //* Send Credentials API
  const sendCredentialsAPI = () => {
    setDisableSendCredentials(true);
    setEnableDoneBtn(false);

    APIConfig.API_Client.post(
      paAPIEndpoints.SEND_ONBOARDING_CREDENTIALS.baseUrl +
        paAPIEndpoints.SEND_ONBOARDING_CREDENTIALS.endpoint,
      {
        reference_id: randomUUID(),
        company_id: companyID,
      },
      { cancelToken: source.token }
    )
      .then((response) => {
        setEnableDoneBtn(true);
        setDisableSendCredentials(true);

        ReactDOM.render(
          <SnackbarMessage
            msgtype="success"
            msg={response?.data?.message || "Credentials sent Successfully"}
          />,
          document.getElementById("snackbar")
        );
      })
      .catch((error) => {
        setEnableDoneBtn(false);
        setDisableSendCredentials(false);

        // * snackbar for error
        ReactDOM.render(
          <SnackbarMessage
            msgtype="Error"
            msg={error?.response?.data?.message || "Something went wrong !"}
          />,
          document.getElementById("snackbar")
        );
      });
  };

  //* DMO Merchant Onboarding API
  // const dmoMerchantOnBoardingAPI = () => {
  //   APIConfig.API_Client.post(
  //     paAPIEndpoints.MERCHANT_ONBOARDING_DMO.baseUrl +
  //       paAPIEndpoints.MERCHANT_ONBOARDING_DMO.endpoint,
  //     {
  //       reference_id: dmoReferenceID,
  //       provider_code: "yesb",
  //       consumer_urn: consumerURN,
  //     },
  //     { cancelToken: source.token }
  //   )
  //     .then((response) => {
  //       console.log("🚀 🚀 🚀 ~ file: OnBoardCredentialsPA.js:92 ~ .then ~ response:", response);

  //       ReactDOM.render(
  //         <SnackbarMessage
  //           msgtype="success"
  //           msg={response?.data?.message || "Merchant onboarding request in process"}
  //         />,
  //         document.getElementById("snackbar")
  //       );
  //     })
  //     .catch((error) => {
  //       console.log("🚀 🚀 🚀 ~ file: OnBoardCredentialsPA.js:101 ~ dmoMerchantOnBoardingAPI ~ error:", error);
  //       // * snackbar for error
  //       ReactDOM.render(
  //         <SnackbarMessage
  //           msgtype="Error"
  //           msg={error?.response?.data?.message || "Something went wrong !"}
  //         />,
  //         document.getElementById("snackbar")
  //       );
  //     });
  // };

  return (
    <div className="pa-onboard-summary-details">
      <FormHeading headingText="Confirmation" />

      <div className="pa-summary-action-wrapper">
        <p className="pa-summary-action-label">
          Company has been onboarded successfully.
        </p>
        <div className="pa-summary-button-container">
          <button
            // className={`pa-summary-btn-submit`}
            className={`formik-btn-submit ${
              !disableSendCredentials ? "active" : "disabled"
            }`}
            disabled={disableSendCredentials}
            onClick={sendCredentialsAPI}
          >
            {"Generate Credentials"}
          </button>
        </div>
      </div>

      {/* DMO // PGDR */}
      {/* <FormHeading headingText="Merchant Onboarding" />

      <div class="dmo-table" role="region" tabindex="0">
        <table>
          <thead>
            <tr>
              <th>Consumer URN</th>
              <th>Provider</th>
              <th>Reference ID</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{consumerURN || "-"}</td>
              <td>{"Yes Bank"}</td>
              <td className="reference-id-field">{dmoReferenceID}</td>
            </tr>
          </tbody>
        </table>
        <div className="dmo-button-container">
          <button
            // className={`dmo-btn-submit`}
            className={`formik-btn-submit ${
              consumerURN ? "active" : "disabled"
            }`}
            onClick={dmoMerchantOnBoardingAPI}
          >
            {"Submit"}
          </button>
        </div>
      </div> */}

      <div className="pa-done-button-container">
        <button
          className={`formik-btn-submit ${
            enableDoneBtn ? "active" : "disabled"
          }`}
          type="button"
          // onClick={toCompanyDetails}
          // onClick={toDMO}
          onClick={toCompanyDetails}
          disabled={!enableDoneBtn}
        >
          Done
        </button>
      </div>
    </div>
  );
};

export default OnBoardCredentialsPA;
