import React, { useContext } from "react";

// context imports
import OnBoardCompanyPAContext from "../../../contexts/CustomerManagementPAContext/OnBoardCompanyPAContext";

// ui components
import TabContext from "@material-ui/lab/TabContext";
import TabPanel from "@material-ui/lab/TabPanel";
import TabList from "@material-ui/lab/TabList";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import TabHeading from "../../../UI/TabHeading/TabHeading";
import SettlementAccountLayout from "../CompanyDetailsPA/SettlementAccountLayout/SettlementAccountLayout";
import VPA from "../DMOandVPAPA/VPA";
// #
import CompanyDetailsPA from "../CompanyDetailsPA/CompanyDetailsPA";
import ModuleProviderPA from "../ModuleProviderPA/ModuleProviderPA";
import OnBoardCredentialsPA from "../OnBoardCredentialsPA/OnBoardCredentialsPA";

// styles import
import "./OnBoardCompanyPA.scss";

const OnBoardCompanyPA = () => {
  // ^ context
  const { onboardingStep, tabDisabled } = useContext(OnBoardCompanyPAContext);

  return (
    <div className="onboard-customer-prod-page">
      <div className="onboard-customer-prod-content">
        <div className="onboard-customer-prod">
          <TabHeading headingText="Onboard Company" />
        </div>

        {/* Tabs */}
        <div className="onboard-customer-forms">
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={onboardingStep}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList>
                  <Tab
                    className="companyDetails onboard-tab"
                    disabled={tabDisabled.companyDetailsPA}
                    label="Company Details"
                    value="companyDetailsPA"
                  />
                  <Tab
                    className="moduleProvider onboard-tab"
                    disabled={tabDisabled.moduleProviderTabPA}
                    label="Module & Provider"
                    value="moduleProviderTabPA"
                  />
                  <Tab
                    className="settlementAccount onboard-tab"
                    disabled={tabDisabled.settlementAccountPA}
                    label="Settlement Account"
                    value="settlementAccountPA"
                  />

                  <Tab
                    className="summary onboard-tab"
                    disabled={tabDisabled.dmo}
                    label="VPA"
                    value="dmo"
                  />

                  <Tab
                    className="summary onboard-tab"
                    disabled={tabDisabled.credentialsPA}
                    label="Credentials"
                    value="credentialsPA"
                  />
                </TabList>
              </Box>
              <TabPanel value="companyDetailsPA">
                <div id="companyDetailsPA">
                  <CompanyDetailsPA />
                </div>
              </TabPanel>
              <TabPanel value="moduleProviderTabPA">
                <div id="moduleProviderTabPA">
                  <ModuleProviderPA />
                </div>
              </TabPanel>
              <TabPanel value="settlementAccountPA">
                <div id="settlementAccountPA">
                  {/* <SettlementAccountPA /> */}
                  <SettlementAccountLayout />
                </div>
              </TabPanel>

              <TabPanel value="dmo">
                <div id="dmo">
                  <VPA />
                </div>
              </TabPanel>
              <TabPanel value="credentialsPA">
                <div id="credentialsPA">
                  <OnBoardCredentialsPA />
                </div>
              </TabPanel>
            </TabContext>
          </Box>
        </div>
        {/* Tabs ends */}
      </div>
    </div>
  );
};

export default OnBoardCompanyPA;
