import React from "react";

// styles import
import "./FeatureUnavailable.scss";

const FeatureUnavailable = ({
  defaultDescription = "Feature currently not available for this environment",
}) => {
  return (
    <div className="warn-wrapper">
      <p class="warn-text">{defaultDescription}</p>
    </div>
  );
};

export default FeatureUnavailable;
